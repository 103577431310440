<template>
  <v-app>
    <v-card>
      <v-card-title>
        Upgrade class
      </v-card-title>
      <v-card-text>
        <p>You are about to upgrade students to a new class. This action is not automatically reversible. Please make sure class details before you continue.</p>
        <div class="row ">
          <div class="col-12">
            <h6>Enter new class details</h6><hr/>
          </div>
          <div class="col-6">
            <v-text-field outlined dense v-model="academic_class_upgrade.title"
                          placeholder="Class name" label="Class name"></v-text-field>
          </div>

          <div class="col-6">
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="academic_class_upgrade.start_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="academic_class_upgrade.start_date"
                    label="Commencement Date"
                    prepend-inner-icon="mdi-calendar"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="academic_class_upgrade.start_date"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(academic_class_upgrade.start_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <!--          <span class="text-danger" v-if="$v.academic_class_upgrade.program_id.$error">Start Date is required</span>-->
          </div>
          <div class="col-6">
            <v-select :items="['morning', 'day', 'evening', 'night']" v-model="academic_class_upgrade.shift"
                      outlined dense placeholder="Class shift" label="Class shift"></v-select>
            <span class="text-danger" v-if="$v.academic_class_upgrade.shift.$error">Shift is required</span>
          </div>

          <div class="col-6">
            <v-select :items="rooms" item-text="title" item-value="id" v-model="academic_class_upgrade.room_id"
                      outlined dense placeholder="Room" label="Room"></v-select>
          </div>
          <div class="col-12">
            <v-select :items="running_mode" item-value="value" item-text="title"
                      v-model="academic_class_upgrade.running_mode"
                      outlined dense placeholder="Running mode " label="Running mode"></v-select>
          </div>
          <div class="col-6" v-if="academic_class_upgrade.running_mode =='online'">
            <v-text-field outlined dense v-model="academic_class_upgrade.online_class_url"
                          placeholder="Online class URL" label="Online class URL"></v-text-field>

          </div>
          <div class="col-6" v-if="academic_class_upgrade.running_mode =='online'">
            <v-select :items="online_class_type" item-value="value" item-text="title"
                      v-model="academic_class_upgrade.online_class_type"
                      outlined dense placeholder="Class type " label="Class type"></v-select>
          </div>
          <div class="col-12">
            <h6>Select new semester/year to upgrade students</h6><hr/>
          </div>
          <div class="col-6">
            <v-select
                :items="gradesLevels"
                v-model="academic_class_upgrade.grade_id"
                outlined
                dense
                label="Semester/year"
                item-text="title"
                item-value="id"
            >

            </v-select>
            <span class="text-danger"
                  v-if="$v.academic_class_upgrade.grade_id.$error">Semester/year is required</span>
          </div>


          <div class="col-6">
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="academic_class_upgrade.end_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="academic_class_upgrade.end_date"
                    label="Class completion date"
                    prepend-inner-icon="mdi-calendar"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="academic_class_upgrade.end_date"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(academic_class_upgrade.end_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

          </div>

          <div class="col-12">
            <v-switch
                :label="academic_class_upgrade.upgrade_all_students?'Upgrade all students in this class':'Upgrade selected students in this class'"
                v-model="academic_class_upgrade.upgrade_all_students"
            >
            </v-switch>
          </div>

          <div class="col-12">
            <div class="col-12" v-if="assigned_students.length && !academic_class_upgrade.upgrade_all_students">
              <table class="table table-stripe">
                <thead>
                <tr>
                <th style="width: 40px !important; white-space: pre-wrap;">
                  <v-checkbox @change="selectAll()" v-model="checkAll" outlined dense></v-checkbox>
                </th>
                <th colspan="2">Student detail</th>
                <th>Symbol Number</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(student, index) of assigned_students" :key="index">
                  <td style="width: 40px !important; white-space: pre-wrap;">
                    <v-checkbox v-model="selected" :value="student.id" outlined
                                dense
                               ></v-checkbox>
                  </td>
                  <td>
                    <div class="symbol-label">
                      <img v-if="student.photo"
                           :src="student.image_path.thumb"
                           class="cursor-pointer" alt=""
                           style="height: 30px;">
                      <span v-else class="symbol symbol-35 symbol-light-success">
                        <span class="symbol-label font-size-h5 font-weight-bold cursor-pointer"> {{(student.full_name) ? student.full_name.charAt(0) : null }} </span>
                      </span>
                    </div>
                  </td>
                  <td>{{student.full_name}}</td>
                  <td>{{ student.setting ? student.setting.symbol_no : "N/A" }}</td>


                </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="btn btn-primary text-white"
            text
            @click="createAndUpdate"
        >
          Upgrade
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import RoomsService from "@/core/services/rooms/RoomsService";

const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const roomService = new RoomsService();

export default {
  name: "academic_class-upgrade",
  validations: {


    academic_class_upgrade: {
      title: {required},
      shift: {required},
      grade_id: {required}
    }
  },
  data() {
    return {
      dialog: false,
      menu: false,
      menu2: false,
      isBusy: false,
      checkAll: false,
      edit: false,
      levels: [],
      programs: [],
      assigned_students: [],
      academicYears: [],
      rooms: [],
      selected: [],
      running_mode: [
        {
          'title': 'Online',
          'value': 'online',
        },
        {
          'title': 'Campus',
          'value': 'campus',
        }
      ],
      online_class_type: [
        {
          'title': 'Zoom',
          'value': 'zoom',
        },
        {
          'title': 'Microsoft Team',
          'value': 'teams',
        },
        {
          'title': 'Google Meet',
          'value': 'meet',
        }
      ],
      gradesLevels: [],
      academic_class: {
        level_id: null,
        program_id: null,
        grade_id: null,
        shift: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        is_active: true
      },
      academic_class_upgrade: {
        grade_id: null,
        shift: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        upgrade_all_students: true,
        is_active: true
      }
    };
  }, mounted() {
    this.getAcademicClass();
    this.getAssignedStudents();
    this.getRooms()
  },

  computed: {
    classId() {
      return this.$route.params.classId;
    }
  },
  methods: {
    open() {
      this.edit = true;
    },

    close() {
      this.edit = false;
    },


    formatDatePickerWise(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month}-${day}`
    },

    getAcademicClass() {
      academicClassService.show(this.classId).then(response => {
        this.academic_class = response.data.class;
        this.academic_class_upgrade.title = this.academic_class.title+' (Upgraded)';
        this.academic_class_upgrade.academic_year_id = this.academic_class.academic_year_id;
        this.academic_class_upgrade.program_id = this.academic_class.program_id;
        this.academic_class_upgrade.level_id = this.academic_class.level_id;
        this.academic_class_upgrade.old_class_id = this.academic_class.id;
        this.getGrades();
      });
    },


    getGrades() {
      gradeLevelService.getHigherGrades(this.academic_class.grade_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getRooms() {
      roomService
          .paginate()
          .then(response => {
            this.rooms = response.data.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },

    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.academic_class_upgrade.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if(!this.academic_class_upgrade.upgrade_all_students)
        {
          this.academic_class_upgrade.student_ids = this.selected;
        }
        this.isBusy = true;
        academicClassService
            .upgradeClass(this.academic_class_upgrade).then(response => {
          this.isBusy = false;
          this.$emit("refresh");
          const classId = response.data.class_id;
          this.$snotify.success("Information updated");
          this.$tabs.close().then(response=>{
            this.$router.push({"name":"academic-class-summary", params:{classId:classId}});
          });

        });

      }
    },



    getAssignedStudents() {
      academicClassService.getAssignedStudents(this.classId).then(response => {
        this.assigned_students = response.data.students;
      });
    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.assigned_students.forEach(student => {
          this.selected.push(student.id);
        })
      }

    },
  resetForm() {
      this.academic_class = {
        level_id: null,
        program_id: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        is_active: true
      }
      this.edit = false
      this.$v.$reset()
      this.hideModal()

    }
  },
};
</script>
